<template>
  <div>
    <v-list dense class="py-0" style="background-color:transparent;">
      <v-list-group
        v-for="item in items.slice(1, 4)"
        :key="item.id"
        no-action
        append-icon=""
      >
        <template v-slot:activator>
          <v-list-item-content class="mx-n1 grey white--text text-none flex-nowrap">
            <v-list-item-title class="px-2 d-flex align-center">
              {{$store.state.settings.gizi ? 'History:' : 'Tanggal Resep:'}} {{formatDate(item.id, 'DD MMM YYYY HH:mm')}}
              <v-icon color="white" class="ml-auto">mdi-chevron-down</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-simple-table dense class="text-uppercase grey--text text--darken-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-small px-0"></th>
                <th class="text-small pl-0">Item</th>
                <th class="text-small">{{$store.state.settings.gizi ? 'Menu' : 'Signa'}}</th>
                <th class="px-0" width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in item.obat" :key="index">
                <td class="text-small pl-0 pr-1" style="font-size:1.5em !important">{{ index + 1 }}.</td>
                <td class="text-small pl-0">{{ item.obat }}</td>
                <td class="text-small">{{ item.aturan }}</td>
                <td class="px-0" width="5%">
                  <v-btn x-small icon @click="copyObat(item)">
                    <v-icon>{{$store.state.settings.gizi ? 'mdi-content-copy' : 'mdi-plus-circle'}}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'ResepData',
  props: {
    data: {
      type: Array|String,
      default: () => ([])
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: this.data.sort((a, b) => b.id - a.id)
    }
  },
  methods: {
    copyObat(item) {
      if(this.$store.state.settings.gizi) {
        this.$emit('copyMenu', item.aturan)
      } else {
        this.$emit('clicked', item)
      }
    }
  }
}
</script>
